@import "./styles/reset.css";
@import "@sproutsocial/seeds-color/dist/seeds-color.css";
@import "@sproutsocial/seeds-typography/dist/seeds-typography.css";
@import '@sproutsocial/seeds-border/dist/seeds-border.css';
@import '@sproutsocial/seeds-space/dist/seeds-space.css';

html {
  font-size: var(--typography-size--200);
  line-height: var(--typography-size--300-line-height);
  font-family: var(--typography-family);
}

body {
  color: var(--color-neutral-800);
  background-color: var(--color-neutral-100);
}

.Header {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 10;
  border-bottom: 1px solid;
  border-color: var(--color-neutral-200);
  background-color: var(--color-neutral-0);
  padding: var(--space-size--350) var(--space-size--450);
}

@media (max-width: '992px') {
  .Header {
    padding: var(--space-size--350) var(--space-size--300);
  }
}

.Loader {
  background: var(--color-neutral-200);
  background-image: linear-gradient(
    288deg,
    rgba(222, 225, 225, 0.7) 32%,
    rgba(222, 225, 225, 0.05),
    rgba(222, 225, 225, 0.7) 68%
  );
  background-size: 400%;
  background-repeat: no-repeat;
  animation: SkeletonShimmer 2.5s linear infinite reverse;
}

@keyframes SkeletonShimmer {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@media (prefers-reduced-motion) {
  .Loader {
    animation: none;
  }
}

.Loader--circle {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-1000);
  margin-right: 8px;
}

.Loader--rectangle {
  width: 300px;
  height: 21px;
  border-radius: var(--border-radius-500);
}